import { useEffect, useState } from 'react';
import { Box, Button, Container, FormControl, Input, InputLabel, MenuItem, Modal, Select, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress } from '@mui/joy';
import { UsersTable } from '../../sections/users';
import Sidenav from '../../components/Sidenav';
import { ApiService } from '../../services/api.service';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  language: string;
}

interface Role {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

const apiService = new ApiService();

const Users = () => {
  const now = new Date();

  const [data, setData] = useState([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [updateUsers, setUpdateUsers] = useState<number | undefined>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    language: "",
    roleId: 0
  });

  const navigate = useNavigate();

  const handleChange = (event: any) => {
    const value = event.target.value;
    
    setUser({
      ...user,
      [event.target.name]: value
    });
  };

  const handleRoleChange = (event: any) => {
    const value = event.target.value;
    
    setUser({
      ...user,
      [event.target.name]: value
    });
  };

  const handleLanguageChange = (event: any) => {
    const value = event.target.value;
    
    setUser({
      ...user,
      [event.target.name]: value
    });
  };

  const fetchUsers = async (page: number = 1, limit: number = 10) => {
    setIsLoading(true);

    try {
      const response = await apiService.get(`/users?page=${page}&limit=${limit}`);

      if (response?.status == 403) {
        navigate('/dashboard');
      }

      setData(response?.data.users);
      setTotal(response?.data.total);
      setTotalPages(response?.data.totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await apiService.get("/roles");
      setRoles(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const createUser = async () => {
    try {
      setUpdateUsers(0);

      const response = await apiService.post("/users", user);
      setUpdateUsers(response?.status);
      
      setUser({
        firstName: "",
        lastName: "",
        email: "",
        language: "",
        roleId: 0
      });

      handleClose();
      fetchUsers();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUsers(currentPage, rowsPerPage);
    fetchRoles();
  }, [currentPage, rowsPerPage, updateUsers]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseFloat(event.target.value);

    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const notify = (message: string) => toast(message);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #FAFAFA',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      {
        isLoading ? (
          <>
            <Sidenav />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10, 
                bgcolor: '#F0F1F5'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Sidenav />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10,
                bgcolor: '#F0F1F5'
              }}
            >
              <Container maxWidth="xl">
                <Stack spacing={3}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={4}
                  >
                    <Stack spacing={1}>
                      <Typography variant="h4">
                        Users
                      </Typography>
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                      >
                      </Stack>
                    </Stack>
                    <div>
                      <Button
                        onClick={handleOpen}
                        startIcon={<AddIcon />}
                        variant="contained"
                        sx={{
                          backgroundColor: '#003D6E',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#005F8C',
                          },
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  </Stack>
                  { data && data.length > 0 ? (
                    <UsersTable
                      count={total}
                      items={data}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      refreshData={fetchUsers}
                    />
                  ) : (
                    <Typography>No users found</Typography>
                  )}
                </Stack>
              </Container>
            </Box>
          </>
        )
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <Typography 
            id="modal-modal-title" 
            variant="h6" 
            component="h2"
          >
            User
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <Input 
              type="text" 
              placeholder="First name" 
              name="firstName" 
              value={user.firstName} 
              fullWidth={true} 
              onChange={handleChange} 
            />
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <Input 
              type="text" 
              placeholder="Last name" 
              name="lastName" 
              value={user.lastName} 
              fullWidth={true} 
              onChange={handleChange} 
            />
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <Input 
              type="text" 
              placeholder="Email" 
              name="email" 
              value={user.email} 
              fullWidth={true} 
              onChange={handleChange} 
              sx={{ 
                marginBottom: '20px'
              }}
            />
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="roleId"
                value={user.roleId}
                label="Role"
                onChange={handleRoleChange}
                sx={{ 
                  marginBottom: '30px'
                }}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>
          <Typography sx={{ mt: 4 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-label">Language</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="language"
                value={user.language}
                label="Language"
                onChange={handleLanguageChange}
                sx={{ 
                  marginBottom: '30px'
                }}
              >
                <MenuItem key='English' value='en'>
                  English
                </MenuItem>
                <MenuItem key='Portuguese' value='pt'>
                  Portuguese
                </MenuItem>
              </Select>
            </FormControl>
          </Typography>
          <Button 
            color="primary" 
            variant="outlined" 
            onClick={handleClose} 
            sx={{ 
              mt: 2, 
              marginRight: 1, 
              borderColor: '#005F8C', 
              color: '#005F8C', 
            }}
          >
            Cancel
          </Button>
          <Button 
            color="primary" 
            variant="contained" 
            onClick={createUser} 
            sx={{ 
              mt: 2,
              backgroundColor: '#003D6E', '&:hover': { 
                backgroundColor: '#005F8C' 
              }
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

Users.getLayout = (page: any) => (
  //<DashboardLayout>
    {page}
  //</DashboardLayout>
);

export default Users;