import { useEffect, useState } from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/joy/CircularProgress';
import Sidenav from '../../components/Sidenav';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../../services/api.service';
import { ApplicationsTable } from '../../sections/applications';

const apiService = new ApiService();

const Applications = () => {
  const navigate = useNavigate();
  
  const now = new Date();

  const [data, setData] = useState([]);
  const [updateApplications, setUpdateApplications] = useState<number | undefined>(0);
  const [isLoading, setIsLoading] = useState(true);
  
  const [application, setApplication] = useState({
    name: "",
    description: ""
  });

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await apiService.get("/applications");
      
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [updateApplications]);

  return (
    <>
      {
        isLoading ? (
          <>
            <Sidenav />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10, 
                bgcolor: '#F0F1F5'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Sidenav />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10, 
                bgcolor: '#F0F1F5'
              }}
            >
              <Container maxWidth="xl">
                <Stack spacing={3}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={4}
                  >
                    <Stack spacing={1}>
                      <Typography variant="h4">
                        Applications
                      </Typography>
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                      >
                      </Stack>
                    </Stack>
                  </Stack>
                  <ApplicationsTable
                    count={data.length}
                    items={data}
                  />
                </Stack>
              </Container>
            </Box>
          </>
        )
      }
    </>
  );
};

Applications.getLayout = (page: any) => (
  //<DashboardLayout>
    {page}
  //</DashboardLayout>
);

export default Applications;