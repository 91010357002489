import { CSSProperties, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Stack, Switch, TextField, Typography } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CircularProgress from '@mui/joy/CircularProgress';
import Sidenav from "../../components/Sidenav";
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from "../../services/api.service";

const apiService = new ApiService();

interface Form {
  id: number;
  name: string;
  description: string;
  sections: Section[];
}

interface Section {
  uuid: string;
  name: string;
  description: string;
  isHidden: boolean;
  questions: Question[];
  nextSection: string;
}

interface Question {
  id: number;
  uuid: string;
  question: string;
  questionType: string;
  answers: answer[];
  isRequired: boolean;
  hasConditionalSection: boolean;
}

interface answer {
  id: number;
  answer: string;
  associatedSection: string|null;
}

const Form = () => {

  // 1 - Remove sectionContent
  // 2 - Remove questionData
  // 3 - Remove form (setForm)

  const role = localStorage.getItem('role');
  
  const { formId } = useParams();

  const [formData, setFormData] = useState<Form>({
    "id": 0,    
    "name": "",
    "description": "",
    "sections": []
  });

  const [sectionContent, setSectionContent] = useState<Section[]>([]);
  const [textField, setTextField] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  
  const getForm = async () => {
    setIsLoading(true);

    try {
      const response = await apiService.get('/forms/' + formId);
      setFormData(response?.data);
    } catch (error) {
      console.error("Error getting form data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getForm();
  }, []);

  const navigate = useNavigate();

  const handleFormDataChange = (event: any) => {
    const value = event.target.value;
    
    setFormData({
      ...formData,
      [event.target.name]: value
    });
  };

  const editSectionName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = event.target;
    const updatedFormData = { ...formData };
  
    updatedFormData.sections = updatedFormData.sections.map((section, i) => 
      i === index ? { ...section, name: value } : section
    );
  
    setFormData(updatedFormData);  
    setSectionContent(updatedFormData.sections);
  };

  const editSectionDescription = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = event.target;
    const updatedFormData = { ...formData };
  
    updatedFormData.sections = updatedFormData.sections.map((section, i) => 
      i === index ? { ...section, description: value } : section
    );
  
    setFormData(updatedFormData);  
    setSectionContent(updatedFormData.sections);
  };

  const setNextSection = (sectionUuid: string, nextSection: string) => {
    const updatedFormData = { ...formData };
  
    updatedFormData.sections = updatedFormData.sections.map((section) => 
      section.uuid === sectionUuid ? { ...section, nextSection: nextSection } : section
    );
  
    setFormData(updatedFormData);  
    setSectionContent(updatedFormData.sections);
  };

  const editQuestion = (sectionUuid: string, questionUuid: string, fieldLabel: string) => {
    if (!formData) return;
  
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        return {
          ...section,
          questions: section.questions.map(question => 
            question.uuid === questionUuid ? { ...question, question: fieldLabel } : question
          )
        };
      }
      return section;
    });
  
    setFormData({
      ...formData,
      sections: updatedSections
    });
  };

  const editQuestionType = (sectionUuid: string, questionUuid: string, newType: string) => {
    if (!formData) return;
  
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        return {
          ...section,
          questions: section.questions.map(question => 
            question.uuid === questionUuid ? { ...question, questionType: newType } : question
          )
        };
      }

      return section;
    });
  
    setFormData({
      ...formData,
      sections: updatedSections
    });
  };

  const setAnswerSection = (sectionUuid: string, questionUuid: string, answerIndex: any, associatedSection: string) => {
    if (!formData) return;
  
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        const questions = [...section.questions];
        const questionIndex = questions.findIndex(question => question.uuid === questionUuid);

        if (questionIndex > -1) {
          const selectedAnswer = questions[questionIndex].answers[answerIndex];
                
          if (selectedAnswer) {
            selectedAnswer.associatedSection = associatedSection;
          }
        }

        return { ...section, questions };
      }

      return section;
    });

    setFormData({
      ...formData,
      sections: updatedSections
    });
  };
  
  const addAnswerOption = (sectionUuid: string, questionUuid: string, option: string) => {
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        const questions = [...section.questions];
        const questionIndex = questions.findIndex(question => question.uuid === questionUuid);

        if (questionIndex > -1 && option && option != "") {
          const answer = {
            id: 0,
            answer: option,
            associatedSection: null
          }
    
          questions[questionIndex].answers.push(answer);
        }

        return { ...section, questions };
      }

      return section;
    });

    setFormData({
      ...formData,
      sections: updatedSections
    });

    setTextField('');
  }

  const deleteAnswerOption = async (sectionUuid: string, questionUuid: string, answerId: number, answerIndex: number) => {
    try {
      await apiService.delete('/answers/' + answerId);

      const updatedSections = formData.sections.map(section => {
        if (section.uuid === sectionUuid) {
          const questions = [...section.questions];
          const questionIndex = questions.findIndex(question => question.uuid === questionUuid);
    
          if (questionIndex > -1) {
            questions[questionIndex].answers.splice(answerIndex, 1);
          }
    
          return { ...section, questions };
        }
    
        return section;
      });
    
      setFormData({
        ...formData,
        sections: updatedSections
      });
    } catch (error) {
      console.error("Error while trying to delete answer: ", error);
    }
  };

  const setRequiredStatus = (sectionUuid: string, questionUuid: string) => {
    if (!formData) return;
  
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        return {
          ...section,
          questions: section.questions.map(question =>
            question.uuid === questionUuid ? { ...question, isRequired: (question.isRequired) ? false : true } : question
          )
        };
      }
  
      return section;
    });
  
    setFormData({
      ...formData,
      sections: updatedSections
    });
  };

  // FIX it

  const setConditionalSection = (sectionUuid: string, questionUuid: string) => {
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        const questions = [...section.questions];
        const questionIndex = questions.findIndex(question => question.uuid === questionUuid);

        if (questionIndex > -1) {
          questions[questionIndex].hasConditionalSection = (questions[questionIndex].hasConditionalSection) ? false : true;
        }

        return { ...section, questions };
      }

      return section;
    });

    setFormData({
      ...formData,
      sections: updatedSections
    });
  }

  const addSection = () => {
    const newSection = {
      uuid: uuidv4(),
      name: '',
      description: '',
      isHidden: false,
      questions: [],
      nextSection: '',
    };

    setFormData(prevFormData => ({
      ...prevFormData,
      sections: [...prevFormData.sections, newSection]
    }));
  };

  const addQuestion = (sectionIndex: number) => {
    const newQuestion = {
      id: 0,
      uuid: uuidv4(),
      question: "",
      questionType: "shortAnswer",
      answers: [],
      isRequired: false,
      hasConditionalSection: false,
    };
  
    const updatedSections = [...formData.sections];
    
    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      questions: [...updatedSections[sectionIndex].questions, newQuestion],
    };
  
    setFormData(prevFormData => ({
      ...prevFormData,
      sections: updatedSections,
    }));
  };  

  const deleteQuestion = async (sectionIndex: number, questionUuid: string, questionId: number) => {
    try {
      await apiService.delete('/questions/' + questionId);
    
      const updatedFormData = { ...formData };
  
      updatedFormData.sections = updatedFormData.sections.map((section, index) => {
        if (index === sectionIndex) {
          return {
            ...section,
            questions: section.questions.filter(question => question.uuid !== questionUuid)
          };
        }
        return section;
      });
    
      setFormData(updatedFormData);
    
      setSectionContent(updatedFormData.sections);
    } catch (error) {
      console.error("Error while trying to delete question: ", error);
    }
  };

  const deleteSection = (sectionUuid: string) => {
    const updatedFormData = { ...formData };

    updatedFormData.sections = updatedFormData.sections.filter(section => section.uuid !== sectionUuid)
  
    setFormData(updatedFormData);
  };

  const updateForm = async () => {
    const body = {
      "name": formData.name,
      "description": formData.description,
      "sections": formData.sections
    }

    try {
      await apiService.patch("/forms/" + formId, body).then((data) => {
        if (data) {
          navigate("/forms");
        }
      });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const style = {
    borderLeft: '4px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px',
    padding: '30px'
  }

  const formHeaderStyle = {
    backgroundColor: '#FFF',
    borderTop: '6px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px',
    padding: '30px'
  }

  const boxStyle = {
    display: 'flex',
    alignItems: 'center',
  }

  const alignLeftStyle = {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
  }

  const alignRightStyle = {
    marginRight: '20px',
    alignItems: 'center',
    marginLeft: '10px',
  }

  const alignRightWithBigMarginStyle = {
    marginRight: '30%'
  }

  const deleteQuestionStyle = {
    marginRight: '20px',
    marginBottom: '-5px',
    cursor: 'pointer'
  }

  const textAreaStyle: CSSProperties = {
    width: '70%',
    padding: '0.375rem 1.75rem 0.375rem 0.75rem',
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    borderRadius: '3px',
    resize: 'none'
  }

  const sectionTextAreaStyle: CSSProperties = {
    width: '70%',
    padding: '0.375rem 1.75rem 0.375rem 0.75rem',
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    borderRadius: '3px',
    resize: 'none',
    marginBottom: '25px'
  }

  const selectStyle = {
    display: 'inline-block',
    width: '200px',
    height: '40px',
    padding: '0.375rem 1.75rem 0.375rem 0.75rem',
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    lineHeight: '1.5',
    color: '#495057',
    verticalAlign: 'middle',
    background: '#fff',
    borderRadius: '3px',
    marginBottom: '5px'
  }

  const inputStyle = {
    width: '70%',
    marginBottom: '15px'
  }

  const inputTitleStyle = {
    width: '70%',
    marginBottom: '25px'
  }

  const labelStyle = {
    marginLeft: '5px',
    marginBottom: '5px',
    paddingTop: '10px',
  }

  const buttonStyle = {
    marginLeft: '10px'
  }

  const optionsStyle = {
    marginBottom: '15px'
  }

  const textFieldStyle = {
    marginBottom: '15px'
  }

  const deleteAnswerOptionStyle = {
    marginRight: '10px',
    cursor: 'pointer',
    fontSize: '20px',
    verticalAlign: 'middle',
  };

  const deleteSectionStyle = {
    marginRight: '10px',
    cursor: 'pointer',
    fontSize: '30px',
    verticalAlign: 'top',
    marginLeft: 'auto'
  };

  const nextSectionDropdown = {
    width: '50%',
    height: '50px',
    padding: '0.375rem 1.75rem 0.375rem 0.75rem',
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    lineHeight: '1.5',
    color: '#495057',
    verticalAlign: 'middle',
    background: '#fff',
    borderRadius: '3px',
    marginBottom: '10px',
    marginTop: '10px',
  }
 
  return (
    <>
      {
        isLoading ? (
          <>
            <Sidenav />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10, 
                bgcolor: '#F0F1F5'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Sidenav />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 10, 
                bgcolor: '#F0F1F5'
              }}
            >
              <Container maxWidth="xl">
                <Stack direction="row" justifyContent="space-between" spacing={4}>
                  <Stack spacing={1}>
                    <Typography variant="h4">
                      Form Editor
                    </Typography>
                    <Stack alignItems="center" direction="row" spacing={1}></Stack>
                  </Stack>
                  <Stack>
                    <Button 
                      onClick={() => {navigate("/forms")}} 
                      startIcon={<ArrowLeftIcon />} 
                      variant="contained"
                      sx={{
                        backgroundColor: '#003D6E',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#005F8C',
                        },
                      }}
                    >
                      Back
                    </Button>
                  </Stack>
                </Stack>
                <Stack style={formHeaderStyle}>
                  <TextField 
                    name="name" 
                    value={formData.name} 
                    variant="outlined" style={inputStyle} 
                    placeholder="Untitled Form" 
                    onChange={handleFormDataChange} 
                  />
                  <TextareaAutosize 
                    name="description" 
                    value={formData.description} 
                    style={textAreaStyle} 
                    minRows={5} 
                    placeholder="Form Description" 
                    onChange={handleFormDataChange} 
                  />
                </Stack>
                {
                  formData.sections.map((section, index) => (
                    <div key={index}>
                      <Stack style={formHeaderStyle}>
                        <Box display="flex" alignItems="center" gap={1}>
                          <TextField 
                            name="name" 
                            value={section.name} 
                            variant="outlined" 
                            style={inputTitleStyle} 
                            placeholder="Untitled Section" 
                            onChange={(event) => editSectionName(event, index)} 
                          />
                          <DeleteForeverIcon 
                            style={deleteSectionStyle} 
                            onClick={() => deleteSection(section.uuid)} 
                          />
                        </Box>
                        <TextareaAutosize 
                          name="section-description" 
                          value={section.description} 
                          style={sectionTextAreaStyle} 
                          minRows={5} 
                          placeholder="Section Description" 
                          onChange={(event) => editSectionDescription(event, index)} 
                        />
                        <Stack>
                          <select style={nextSectionDropdown} onChange={(event) => setNextSection(section.uuid, event.target.value)}>
                            <option value="">Select Next Section</option>
                            {
                              formData.sections.filter((section, i) => i !== index).map((sectionName) => (
                                <option value={sectionName.uuid}>Go to {sectionName.name}</option>
                              ))
                            }
                          </select>
                        </Stack>
                        <br></br>
                        <Stack>
                          {
                            section.questions.map((question) => {
                              if (question.uuid != "") {
                                return (
                                  <div style={style}>
                                    <div style={boxStyle}>
                                      <div key={question.uuid} style={alignLeftStyle}>
                                        <TextField 
                                          id="outlined-basic" 
                                          variant="outlined" 
                                          value={question.question} 
                                          style={inputStyle} 
                                          onChange={(event) => editQuestion(section.uuid, question.uuid, event.target.value)} 
                                        />
                                      </div>
                                      <div style={alignRightStyle}>
                                        <select style={selectStyle} onChange={(event) => editQuestionType(section.uuid, question.uuid, event.target.value)}>
                                          {
                                            question.questionType == 'shortAnswer' ? 
                                              <option value="shortAnswer" selected={true}>Short Answer</option>
                                            : 
                                              <option value="shortAnswer">Short Answer</option>
                                          }
                                          {
                                            question.questionType == 'paragraph' ? 
                                              <option value="paragraph" selected={true}>Paragraph</option>
                                            : 
                                              <option value="paragraph">Paragraph</option>
                                          }
                                          {
                                            question.questionType == 'singleChoice' ? 
                                              <option value="singleChoice" selected={true}>Single Choice</option>
                                            : 
                                              <option value="singleChoice">Single Choice</option>
                                          }
                                          {
                                            question.questionType == 'multipleChoice' ? 
                                              <option value="multipleChoice" selected={true}>Multiple Choice</option>
                                            : 
                                              <option value="multipleChoice">Multiple Choice</option>
                                          }
                                          {
                                            question.questionType == 'file' ? 
                                              <option value="file" selected={true}>File</option>
                                            : 
                                              <option value="file">File</option>
                                          }
                                          {
                                            question.questionType == 'date' ? 
                                              <option value="date" selected={true}>Date</option>
                                            : 
                                              <option value="date">Date</option>
                                          }
                                        </select>
                                      </div>
                                    </div>
                                    <div>
                                      {
                                        question.questionType == "shortAnswer" && 
                                        <TextField 
                                          id="outlined-basic" 
                                          variant="outlined" 
                                          style={inputStyle} 
                                          placeholder={"Customer answer"} 
                                          inputProps={
                                            { readOnly: true }
                                          }
                                        />
                                      }
                                      {
                                        question.questionType == "paragraph" && 
                                        <TextareaAutosize 
                                          readOnly
                                          style={textAreaStyle} 
                                          minRows={5} 
                                          placeholder={"Customer answer"} 
                                        />
                                      }
                                      {
                                        question.questionType == "singleChoice" && 
                                        <div>
                                          <div style={textFieldStyle}>
                                            {
                                              question.answers.map((answer, answerIndex) => 
                                                <div style={boxStyle}>
                                                  <div style={alignLeftStyle}>
                                                    <input type="radio" name={question.uuid} key={answer.answer} value={answer.answer} />
                                                    <label style={labelStyle}>{answer.answer}</label>
                                                    <div style={alignRightStyle}>
                                                      <DeleteForeverIcon 
                                                        style={deleteAnswerOptionStyle} 
                                                        onClick={() => deleteAnswerOption(section.uuid, question.uuid, answer.id, answerIndex)} 
                                                      />
                                                    </div>
                                                  </div>
                                                    {
                                                      (question.hasConditionalSection || answer.associatedSection != '') && 
                                                      <div style={alignRightWithBigMarginStyle}>
                                                        <select style={selectStyle} onChange={(event) => setAnswerSection(section.uuid, question.uuid, answerIndex, event.target.value)}>
                                                          <option value="">Select Section</option>
                                                          {
                                                            formData.sections.filter((section, i) => i !== index).map((sectionName) => (
                                                              sectionName.uuid == answer.associatedSection ? 
                                                                <option value={sectionName.uuid} selected={true}>Go to {sectionName.name}</option>
                                                              :
                                                                <option value={sectionName.uuid}>Go to {sectionName.name}</option>
                                                            ))
                                                          }
                                                        </select>
                                                      </div>
                                                    }
                                                </div>
                                            )}
                                          </div>
                                          <div>
                                            <TextField 
                                              id="outlined-basic" 
                                              variant="outlined" 
                                              style={inputStyle} 
                                              value={textField} 
                                              placeholder="Add an option" 
                                              onChange={(event) => setTextField(event.target.value)} 
                                            />
                                            <Button 
                                              startIcon={<AddIcon />} 
                                              variant="contained" 
                                              style={buttonStyle}
                                              onClick={() => addAnswerOption(section.uuid, question.uuid, textField)}
                                              sx={{
                                                backgroundColor: '#003D6E',
                                                color: 'white',
                                                '&:hover': {
                                                  backgroundColor: '#005F8C',
                                                },
                                              }}
                                            >
                                              Add option
                                            </Button>
                                          </div>
                                        </div>
                                      }
                                      {
                                        question.questionType == "multipleChoice" && 
                                        <div>
                                          <div style={textFieldStyle}>
                                            {
                                              question.answers.map((answer, answerIndex) => 
                                                <div style={boxStyle}>
                                                  <div style={alignLeftStyle}>
                                                    <input type="checkbox" name={question.uuid} key={answer.answer} value={answer.answer} />
                                                    <label style={labelStyle}>{answer.answer}</label>
                                                    <div style={alignRightStyle}>
                                                      <DeleteForeverIcon 
                                                        style={deleteAnswerOptionStyle} 
                                                        onClick={() => deleteAnswerOption(section.uuid, question.uuid, answer.id, answerIndex)} 
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                          <div>
                                            <TextField 
                                              id="outlined-basic" 
                                              variant="outlined" 
                                              style={inputStyle} 
                                              value={textField} 
                                              placeholder="Add an option" 
                                              onChange={(event) => setTextField(event.target.value)}
                                            />
                                            <Button 
                                              startIcon={<AddIcon />} 
                                              variant="contained" 
                                              style={buttonStyle}
                                              onClick={() => addAnswerOption(section.uuid, question.uuid, textField)}
                                              sx={{
                                                backgroundColor: '#003D6E',
                                                color: 'white',
                                                '&:hover': {
                                                  backgroundColor: '#005F8C',
                                                },
                                              }}
                                            >
                                              Add option
                                            </Button>
                                          </div>
                                        </div>
                                      }
                                      {
                                        question.questionType == "file" && 
                                        <div>
                                          <TextField 
                                            type="file" 
                                            style={textFieldStyle} 
                                            inputProps={
                                              { readOnly: true }
                                            }
                                          />
                                        </div>
                                      }
                                      {
                                        question.questionType == "date" && 
                                        <div>
                                          <TextField 
                                            type="date" 
                                            id="date" 
                                            style={textFieldStyle} 
                                            inputProps={
                                              { readOnly: true }
                                            }
                                          />
                                        </div>
                                      }
                                    </div>
                                    <div style={boxStyle}>
                                      <div style={alignLeftStyle}>
                                        <span>Required</span>
                                        { question.isRequired ? (
                                            <>
                                              <Switch 
                                                onClick={() => setRequiredStatus(section.uuid, question.uuid)} 
                                                checked 
                                                sx={{
                                                  '& .MuiSwitch-switchBase.Mui-checked': {
                                                    color: '#003D6E'
                                                  },
                                                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                    backgroundColor: '#003D6E'
                                                  }
                                                }}
                                              />
                                            </>
                                          ) : (
                                            <Switch 
                                              onClick={() => setRequiredStatus(section.uuid, question.uuid)}
                                              sx={{
                                                '& .MuiSwitch-switchBase.Mui-checked': {
                                                  color: '#003D6E'
                                                },
                                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                  backgroundColor: '#003D6E'
                                                }
                                              }}
                                            />
                                          )
                                        }
                                      </div>
                                      {
                                        question.questionType == "singleChoice" && 
                                        <div style={alignLeftStyle}>
                                          Go to section based on answer 
                                          {
                                            question.hasConditionalSection ?
                                              <Switch 
                                                defaultChecked
                                                onClick={() => setConditionalSection(section.uuid, question.uuid)} 
                                                sx={{
                                                  '& .MuiSwitch-switchBase.Mui-checked': {
                                                    color: '#003D6E'
                                                  },
                                                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                    backgroundColor: '#003D6E'
                                                  }
                                                }}
                                              />
                                            :
                                              <Switch 
                                                onClick={() => setConditionalSection(section.uuid, question.uuid)} 
                                                sx={{
                                                  '& .MuiSwitch-switchBase.Mui-checked': {
                                                    color: '#003D6E'
                                                  },
                                                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                    backgroundColor: '#003D6E'
                                                  }
                                                }}
                                              />
                                          }
                                        </div>
                                      }
                                      <div style={alignRightStyle}>
                                        <DeleteForeverIcon 
                                          style={deleteQuestionStyle} 
                                          onClick={() => deleteQuestion(index, question.uuid, question.id)} 
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })
                          }
                          <div className="add-button">
                            <br />
                            <Button 
                              startIcon={<AddIcon />} 
                              variant="contained" 
                              onClick={() => {addQuestion(index)}}
                              sx={{
                                backgroundColor: '#003D6E',
                                color: 'white',
                                '&:hover': {
                                  backgroundColor: '#005F8C',
                                },
                              }}
                            >
                              Add Question
                            </Button>
                          </div>
                        </Stack>
                      </Stack>
                    </div>
                  ))
                }
                <Button 
                  onClick={addSection} 
                  startIcon={<AddIcon />} 
                  variant="contained"
                  sx={{
                    backgroundColor: '#003D6E',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#005F8C',
                    },
                  }}
                >
                  Add Section
                </Button>
                <Button 
                  onClick={() => updateForm()} 
                  startIcon={<DoneIcon />} 
                  variant="contained" 
                  style={buttonStyle}
                  sx={{
                    backgroundColor: '#003D6E',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#005F8C',
                    },
                  }}
                >
                  Save Form
                </Button>
              </Container>
            </Box>
          </>
        )
      }
    </>
  )
}

export default Form;